import { useFloating, FloatingPortal, useDismiss, FloatingFocusManager, useInteractions, FloatingOverlay } from '@floating-ui/react'
import { RewardCalculator } from './RewardCalculator'

import styles from './RewardCalculatorPortalFlow.css'

export function  RewardCalculatorPortalFlow({ isOpen, onChange, link }) {
  return (
    <div>
      <RewardCalculatorPortal {...{ isOpen, link }} onClose={handleClose} />
    </div>
  )
  function handleClose() {
    onChange(false)
  }
}

function RewardCalculatorPortal({ isOpen, onClose, link }) {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: onClose,
  })

  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([ dismiss ])

  return isOpen && (
    <FloatingPortal>
      <FloatingFocusManager {...{ context }} modal>
        <FloatingOverlay
          // eslint-disable-next-line @kaliber/layout-class-name
          className={styles.componentRewardCalculatorPortal}
          lockScroll={isOpen}
          {...getReferenceProps()}
        >
          <div
            ref={refs.setFloating}
            aria-hidden={!isOpen}
            className={styles.rewardCalculatorWrapper}
            {...getFloatingProps()}
          >
            <div className={styles.rewardCalculatorContainer}>
              <RewardCalculator {...{ onClose, link }} />
            </div>
          </div>
        </FloatingOverlay>
      </FloatingFocusManager>
    </FloatingPortal>
  )
}
