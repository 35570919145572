export function calculateSalaryByScale(form, salaryData) {
  const { scale, period, hours: selectedHours, holidayPay, extraMonth, ebb, basicPay } = form
  const parsedScale = parseInt(scale, 10)
  const currentScale = salaryData.find(({ scale: s }) => s === parsedScale)
  const {
    holiday: holidayPayMultiplier,
    extraMonth: extraMonthMultiplier,
    ebbMultiplier: currentEbbMultiplier,
    salaryMin: salaryMinByScale,
    salaryMax: salaryMaxByScale,
    hours: defaultHours
  } = currentScale

  const calculationProps = {
    period,
    defaultHours,
    selectedHours,
    holidayPay,
    extraMonth,
    ebb,
    basicPay,
    currentEbbMultiplier,
    holidayPayMultiplier,
    extraMonthMultiplier
  }

  const salaryMin = calculateAdjustedSalary({ salaryByScale: salaryMinByScale, ...calculationProps })
  const salaryMax = calculateAdjustedSalary({ salaryByScale: salaryMaxByScale, ...calculationProps  })

  return {
    salaryMin,
    ebbMultiplier: currentEbbMultiplier,
    salaryMax,
    scale: currentScale,
  }
}

function calculateAdjustedSalary({ salaryByScale, period, defaultHours, selectedHours, holidayPay, extraMonth, ebb, basicPay, currentEbbMultiplier, extraMonthMultiplier, holidayPayMultiplier }) {
  const salaryByPeriod = getSalaryByPeriod(salaryByScale, period)
  const salaryAdjustedForHours = adjustSalaryByHours(salaryByPeriod, defaultHours, selectedHours)

  const holidayBonusValue = getHolidayBonus(salaryAdjustedForHours, holidayPay, holidayPayMultiplier)
  const extraMonthBonusValue = getExtraMonthBonus(salaryAdjustedForHours, extraMonth, extraMonthMultiplier)
  const ebbBonusValue = getEbbBonus(salaryAdjustedForHours, ebb, currentEbbMultiplier)

  const salary = [
    basicPay && salaryAdjustedForHours,
    holidayPay && holidayBonusValue,
    extraMonth && extraMonthBonusValue,
    ebb && ebbBonusValue
  ].filter(Boolean).reduce((result, x) => result + x, 0)
  return salary
}

function getSalaryByPeriod(salary, period) {
  return period === 'year' ? salary * 12 : salary
}

function adjustSalaryByHours(salary, defaultHours, selectedHours) {
  return (salary / defaultHours) * selectedHours
}

function getHolidayBonus(salary, holidayPay, holidayPayMultiplier) {
  return holidayPay ? (salary * holidayPayMultiplier) : 0
}

function getExtraMonthBonus(salary, extraMonth, extraMonthMultiplier) {
  return extraMonth ? (salary * extraMonthMultiplier) : 0
}

function getEbbBonus(salary, ebb, currentEbbMultiplier) {
  return ebb ? (salary * currentEbbMultiplier) : 0
}
