import { useTranslate } from '/machinery/I18n'
import { Toggles } from '/features/buildingBlocks/reward-calculator/Toggles'
import { useFormField, snapshot } from '@kaliber/forms'
import { calculateSalaryByScale } from './calculateSalaryByScale'
import { useRewardCalculatorFields } from './useRewardCalculatorFields'
import { ButtonLinkPrimary } from '/features/buildingBlocks/Button'
import { trackInteraction } from '/features/buildingBlocks/reward-calculator/trackInteraction'
import { useSpring, animated } from 'react-spring'
import { Icon } from '/features/buildingBlocks/Icon'
import { salaryData } from './salaryData'
import { HoursPerWeek } from '/features/buildingBlocks/reward-calculator/HoursPerWeek'

import closeIcon from '/images/icons/close.raw.svg'
import styles from './RewardCalculator.css'

const initialScale = 7

export function RewardCalculator({ onClose, link }) {
  const { __ } = useTranslate()
  const initialData = React.useMemo(() => salaryData.find(item => item.scale === initialScale), [])
  const { form, fields } = useRewardCalculatorFields({ initialData })

  const [{ salaryMin, salaryMax, ebbMultiplier }, setRewardValues] = React.useState({
    salaryMin: initialData.salaryMin,
    salaryMax: initialData.salaryMax,
    ebbMultiplier: initialData.ebbMultiplier,
  })

  return (
    <div className={styles.component}>
      <div className={styles.periodAndClose}>
        <PeriodView layoutClassName={styles.periodLayout} field={fields.period} />
        <CloseButton layoutClassName={styles.closeLayout} {...{ onClose }} />
      </div>
      <HoursPerWeek
        field={fields.hours}
        layoutClassName={styles.hoursLayout}
        salaryScaleField={fields.scale}
        {... { form }}
      />
      <SalaryScale
        layoutClassName={styles.scaleLayout}
        field={fields.scale}
        {...{ initialScale, salaryData }}
      />
      <Result
        layoutClassName={styles.resultLayout}
        onSetRewardValues={setRewardValues}
        {...{ fields, form, initialData, salaryMin, salaryMax }}
      />
      <Toggles
        holidayField={fields.holidayPay}
        basicPayField={fields.basicPay}
        extraMonthField={fields.extraMonth}
        ebbField={fields.ebb}
        {...{ ebbMultiplier }}
      />
      {link?.url && link?.label && (
        <ButtonLinkPrimary
          layoutClassName={styles.buttonLayout}
          dataX='link-to-detail-page'
          label={link.label}
          href={link.url}
        />
      )}
      <p className={styles.explainText}>{__`reward-calculator-salary-explain`}</p>
    </div>
  )
}

function CloseButton({ onClose, layoutClassName }) {
  return (
    <button
      type='button'
      onClick={() => onClose()}
      data-x='close-overlay'
      className={layoutClassName}
    >
      <Icon icon={closeIcon} />
    </button>
  )
}

function PeriodView({ field, layoutClassName }) {
  const { __ } = useTranslate()
  const { name, state, eventHandlers: { onChange } } = useFormField(field)
  const { value } = state

  return (
    <div role="radiogroup" className={cx(styles.componentPeriodView, layoutClassName)}>
      <label className={cx(styles.periodLabel, value === 'month' && styles.active)}>
        <input
          id={`${name}_month`}
          type='radio'
          value='month'
          defaultChecked={value === 'month'}
          className={styles.inputPeriod}
          aria-checked={value === 'month'}
          data-x='monthly-view'
          {...{ onChange, name }}
        />
        {__`monthly`}
      </label>

      <label className={cx(styles.periodLabel, value === 'year' && styles.active)}>
        <input
          id={`${name}_year`}
          type='radio'
          value='year'
          defaultChecked={value === 'year'}
          className={styles.inputPeriod}
          aria-checked={value === 'year'}
          data-x='yearly-view'
          {...{ onChange, name }}
        />
        {__`yearly`}
      </label>
    </div>
  )
}

function SalaryScale({ salaryData, field, layoutClassName }) {
  const { __ } = useTranslate()
  const { name, state, eventHandlers } = useFormField(field)
  const { value } = state
  const parsedScale = parseInt(value, 10)
  const currentScaleData = salaryData.find(({ scale: s }) => s === parsedScale)

  const scaleLabel = currentScaleData?.label || currentScaleData.scale

  return (
    <div className={cx(styles.componentSalaryScale, layoutClassName)}>
      <p>{__`salary-scale`}</p>
      <input
        id={name}
        aria-label={__`input-label`}
        aria-valuemin={salaryData[0].scale}
        aria-valuemax={salaryData.length}
        aria-valuenow={Math.round(value)}
        step={0.01}
        className={styles.scaleInput}
        min={salaryData[0].scale}
        max={salaryData.length}
        type='range'
        data-x='salary-scale'
        defaultValue={value}
        onKeyDown={handleKeyDown}
        {...eventHandlers}
        {...{ name }}
        style={{
          '--value': value,
          '--min': salaryData[0].scale,
          '--max': salaryData.length,
        }}
      />
      <div className={styles.valuesScales}>
        <span className={styles.standardValue}>{salaryData[0].scale}</span>
        <span className={styles.scaleValue}>{scaleLabel}</span>
        <span className={styles.standardValue}>{'SKB'}</span>
      </div>
    </div>
  )
  function handleKeyDown(/** @type {import('react').KeyboardEvent} */e) {
    if ((e.key === 'ArrowRight' || e.key === 'ArrowUp') && value !== salaryData.length) {
      e.preventDefault()
      eventHandlers.onChange(value + 1)
    }
    if ((e.key === 'ArrowLeft' || e.key === 'ArrowDown') && value !== salaryData[0].scale) {
      e.preventDefault()
      eventHandlers.onChange(value - 1)
    }
  }
}

function Result({ form, initialData, onSetRewardValues, salaryMin, salaryMax, layoutClassName }) {
  const { __ } = useTranslate()

  const { scale, hours, period, basicPay, extraMonth, holidayPay, ebb } = initialData
  useOnRewardFieldChange('scale', 'slide', handleRewardFieldChange, { form, initialData: scale, normalize: x => parseInt(x, 10) })
  useOnRewardFieldChange('hours', 'click', handleRewardFieldChange, { form, initialData: hours })
  useOnRewardFieldChange('period', 'click', handleRewardFieldChange, { form, initialData: period })
  useOnRewardFieldChange('basicPay', 'toggle', handleRewardFieldChange, { form, initialData: basicPay })
  useOnRewardFieldChange('extraMonth', 'toggle', handleRewardFieldChange, { form, initialData: extraMonth })
  useOnRewardFieldChange('holidayPay', 'toggle', handleRewardFieldChange, { form, initialData: holidayPay })
  useOnRewardFieldChange('ebb', 'toggle', handleRewardFieldChange, { form, initialData: ebb })

  return (
    <div
      aria-label={`${__`minimum`} ${formatSalary(salaryMin)} euro, ${__`maximum`} ${formatSalary(salaryMax)} euro`}
      aria-live="polite"
      className={cx(styles.componentResult, layoutClassName)}
    >
      <Salary
        label={__`minimum`}
        value={salaryMin}
      />

      <Salary
        label={__`maximum`}
        value={salaryMax}
      />
    </div>
  )

  function handleRewardFieldChange({ salaryMin, salaryMax, ebbMultiplier }) {
    onSetRewardValues({ salaryMin, salaryMax, ebbMultiplier })
  }
}

function useOnRewardFieldChange(fieldName, action, onChange, { form, initialData, normalize = x => x }) {
  const currentValueRef = React.useRef(initialData)

  React.useEffect(
    () => {
      const field = form.fields[fieldName]
      const unsubscribe = snapshot.subscribe(field, x => {
        if (normalize(x.value) === normalize(currentValueRef.current)) return
        currentValueRef.current = x.value
        const formValue = snapshot.get(form).value
        const { salaryMin, salaryMax, ebbMultiplier } = calculateSalaryByScale(formValue, salaryData)

        onChange({ salaryMin, salaryMax, ebbMultiplier })
        trackInteraction(action, field)
      })

      return () => unsubscribe()
    },
    []
  )
}

function Salary({ label, value }) {
  const { value: salary } = useSpring({
    config: { duration: 250 },
    value,
    from: { value: 0 },
  })

  return (
    <div className={styles.componentSalary}>
      <span className={styles.label}>{label}</span>
      <span aria-hidden="true" className={styles.value}>
        € <animated.span>{salary.to(x => formatSalary(x))}</animated.span>
      </span>
    </div>
  )
}

function formatSalary(salary) {
  if (salary === 0) return '0'
  const formattedSalary = (salary / 1000).toFixed(3)
  return parseFloat(formattedSalary) < 1
    ? formattedSalary.slice(2).replace(/^0+/, '')
    : formattedSalary
}
