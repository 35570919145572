import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

export function trackInteraction(action, field) {
  pushToDataLayer({
    event: 'interaction',
    metadata: {
      event: {
        interaction: {
          title: 'reward-calculator',
          type: getTypeNameFromField(field.name),
          action,
        }
      }
    }
  })
}

function getTypeNameFromField(field) {
  const [, ...fieldName] = field.split('.')
  const type = {
    period: 'periode',
    hours: 'uren',
    scale: 'schaal',
    basicPay: 'basisloon',
    holidayPay: 'vakantiegeld',
    extraMonth: '13maand',
    ebb: 'EBB'
  }
  return type[fieldName.join('.')]
}
