export const salaryData = [
  {
    'scale': 1,
    'salaryMin': 1351.74,
    'salaryMax': 1929.89,
    'ebbMultiplier': 0.09,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 2,
    'salaryMin': 1572.76,
    'salaryMax': 2245.24,
    'ebbMultiplier': 0.09,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 3,
    'salaryMin': 1758.76,
    'salaryMax': 2510.76,
    'ebbMultiplier': 0.09,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 4,
    'salaryMin': 1968.98,
    'salaryMax': 2812.66,
    'ebbMultiplier': 0.09,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 5,
    'salaryMin': 2344.98,
    'salaryMax': 3349.03,
    'ebbMultiplier': 0.09,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 6,
    'salaryMin': 2716.94,
    'salaryMax': 3880.02,
    'ebbMultiplier': 0.1,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 7,
    'salaryMin': 3179.19,
    'salaryMax': 4540.36,
    'ebbMultiplier': 0.1,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 8,
    'salaryMin': 3711.55,
    'salaryMax': 5300.48,
    'ebbMultiplier': 0.1,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 9,
    'salaryMin': 4292.40,
    'salaryMax': 6130.64,
    'ebbMultiplier': 0.11,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 10,
    'salaryMin': 5115.32,
    'salaryMax': 7307.58,
    'ebbMultiplier': 0.11,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 11,
    'salaryMin': 6155.29,
    'salaryMax': 8793.26,
    'ebbMultiplier': 0.11,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 12,
    'salaryMin': 8266.02,
    'salaryMax': 11808.60,
    'ebbMultiplier': 0.13,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 40,
    'label': 'SKA'
  },
  {
    'scale': 13,
    'salaryMin': 9789.10,
    'salaryMax': 13984.45,
    'ebbMultiplier': 0.17,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 40,
    'label': 'SKB'

  }
]
