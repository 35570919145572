import { required } from '@kaliber/forms/validation'
import { useForm } from '@kaliber/forms'

export function useRewardCalculatorFields({ initialData }) {
  const { form } = useForm({
    initialValues: {
      period: 'month',
      hours: initialData.hours,
      scale: initialData.scale,
      salaryMin: initialData.min,
      salaryMax: initialData.max,
      basicPay: true,
      holidayPay: false,
      extraMonth: false,
      ebb: false,
    },
    fields: {
      period: required,
      hours: required,
      salaryMin: required,
      salaryMax: required,
      scale: required,
      basicPay: required,
      holidayPay: required,
      extraMonth: required,
      ebb: required,
    },
  })

  return { form, fields: form.fields }
}
