import { useTranslate } from '/machinery/I18n'
import { useBooleanFormField } from '@kaliber/forms'
import { Tooltip } from '/features/buildingBlocks/reward-calculator/Tooltip'

import styles from './Toggles.css'

export function Toggles({
  basicPayField,
  holidayField,
  extraMonthField,
  ebbField,
  ebbMultiplier
}) {
  const { __ } = useTranslate()

  return (
    <div className={styles.component}>
      <Toggle field={basicPayField} label={__`basic-salary`} />
      <Toggle field={holidayField} label={`${__`holiday-money`} (8%)`} />
      <Toggle field={extraMonthField} label={__`extra-month`} />
      <ToggleTooltip ebbValue={ebbMultiplier} field={ebbField} label={__`employee-benefit-budget`} />
    </div>
  )
}

function ToggleTooltip({ field, label, ebbValue }) {
  const { __ } = useTranslate()

  return (
    <Base {...{ field, label, ebbValue }}>
      <Tooltip title={__`employee-benefit-budget`} text={__`explain-tooltip`} />
    </Base>
  )
}

function Toggle({ field, label }) {
  return (
    <Base {...{ field, label }} />
  )
}

function Base({ field, label, ebbValue = undefined, children = undefined }) {
  const { name, state, eventHandlers: { onChange } } = useBooleanFormField(field)
  const { value } = state
  return (
    <div className={styles.componentBase}>
      <div className={styles.content} id={`${name}-label`}>
        <span>{label}</span>
        {ebbValue && <span> &nbsp;({Number(ebbValue * 100)}%)&nbsp; </span>}
        {children}
      </div>
      <label className={styles.toggleContainer}>
        <input
          aria-label={label}
          aria-checked={value}
          className={styles.inputToggle}
          type="checkbox"
          role="switch"
          defaultChecked={value}
          {... { name, onChange }}
        />
        <span className={styles.slider} />
      </label>
    </div>
  )
}
